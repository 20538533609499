<template>
  <div>
    <v-form>
      <p class="mb-3">
        What would the product be for an aldol condensation between diethylketone and 1 equivalent
        of
        <stemble-latex content="$p\text{-tolualdehyde?}$" />
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-3"
          :value="option.value"
        >
          <template #label>
            <img :src="option.img" alt="" style="max-width: 243px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_51LPPL7_Q7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          img: '/img/assignments/UCI_Prelab7_Q7_optionA.png',
          value: 'a',
        },
        {
          img: '/img/assignments/UCI_Prelab7_Q7_optionB.png',
          value: 'b',
        },
        {
          img: '/img/assignments/UCI_Prelab7_Q7_optionC.png',
          value: 'c',
        },
        {
          img: '/img/assignments/UCI_Prelab7_Q7_optionD.png',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
